
import { defineComponent } from 'vue'
import Logo from '@/components/particles/common/Logo.vue'
import HeaderItem from '@/components/particles/MainLayout/Header/HeaderItem.vue'
import { deleteToken } from '@/helpers/token'

export default defineComponent({
  components: {  HeaderItem },
  setup() {
    return { deleteToken }
  },
})
