import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-white z-50 sticky top-0 px-6 py-2.5" }
const _hoisted_2 = { class: "max-w-screen-xl mx-auto w-full flex items-center justify-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderItem = _resolveComponent("HeaderItem")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "self-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'HomeView' })))
        }),
        _createVNode(_component_HeaderItem, {
          iconName: "dashboard",
          iconSize: "icon-md",
          "route-link": "HomeView",
          "active-routes": ['HomeView']
        }),
        _createVNode(_component_HeaderItem, {
          class: "max-w-[220px]",
          iconName: "building",
          iconSize: "icon-md",
          linkTitle: _ctx.$t('HEADER_LINK_TITLE_REAL_ESTATE'),
          linkDescription: _ctx.$t('HEADER_LINK_DESCRIPTION_REAL_ESTATE'),
          "route-link": "RealEstateView",
          "active-routes": ['RealEstateView']
        }, null, 8, ["linkTitle", "linkDescription"]),
        _createVNode(_component_HeaderItem, {
          class: "max-w-[220px]",
          iconName: "objet",
          iconSize: "icon-md",
          linkTitle: _ctx.$t('HEADER_LINK_TITLE_ACTIVITY'),
          linkDescription: _ctx.$t('HEADER_LINK_DESCRIPTION_ACTIVITY'),
          "route-link": "ActivityView",
          "active-routes": ['ActivityView']
        }, null, 8, ["linkTitle", "linkDescription"]),
        _createVNode(_component_HeaderItem, {
          class: "max-w-[220px]",
          iconName: "activity",
          iconSize: "icon-md",
          linkTitle: _ctx.$t('HEADER_LINK_TITLE_MONITORING'),
          linkDescription: _ctx.$t('HEADER_LINK_DESCRIPTION_MONITORING'),
          "route-link": "MonitoringView",
          "active-routes": ['MonitoringView']
        }, null, 8, ["linkTitle", "linkDescription"]),
        _createVNode(_component_HeaderItem, {
          class: "max-w-[220px]",
          iconName: "contact",
          iconSize: "icon-md",
          linkTitle: _ctx.$t('HEADER_LINK_TITLE_CONTACT'),
          linkDescription: _ctx.$t('HEADER_LINK_DESCRIPTION_CONTACT'),
          "route-link": "ContactView",
          "active-routes": ['ContactView']
        }, null, 8, ["linkTitle", "linkDescription"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_HeaderItem, {
          iconName: "settings",
          iconSize: "icon-sm",
          "route-link": "MySettingsView",
          "active-routes": [
            'MySettingsView',
            'PasswordSettingsView',
            'UsersSettingsView',
          ]
        }),
        _createVNode(_component_HeaderItem, {
          iconName: "logout",
          iconSize: "icon-sm",
          "route-link": "AuthorizationView",
          "active-routes": ['AuthorizationView'],
          onClick: _ctx.deleteToken
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}