
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    linkTitle: {
      type: String,
      default: '',
    },
    linkDescription: {
      type: String,
      default: '',
    },
    routeLink: {
      type: String,
      required: true,
      default: '',
    },
    activeRoutes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const route = useRoute()
    const isActiveRoute = computed(() => {
      return props.activeRoutes.includes(route.name)
    })
    return {
      isActiveRoute,
    }
  },
})
