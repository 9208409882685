import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-1 text-2xs font-medium text-darkGrey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-2.5 flex rounded-lg max-w-auto cursor-pointer transition-all duration-300 hover:bg-lightGrey", { 'bg-lightGrey': _ctx.isActiveRoute }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: _ctx.routeLink })))
  }, [
    _createVNode(_component_UiIcon, {
      class: _normalizeClass(["self-center cursor-pointer", { 'mr-2.5': _ctx.linkTitle || _ctx.linkDescription }]),
      name: _ctx.iconName,
      size: _ctx.iconSize
    }, null, 8, ["class", "name", "size"]),
    _createElementVNode("div", null, [
      (_ctx.linkTitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["text-base font-medium text-black transition-all duration-300", { 'text-red': _ctx.isActiveRoute }])
          }, _toDisplayString(_ctx.linkTitle), 3))
        : _createCommentVNode("", true),
      (_ctx.linkDescription)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.linkDescription), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}