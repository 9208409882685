
import { defineComponent } from 'vue'
import Header from '@/components/particles/MainLayout/Header/Header.vue'

export default defineComponent({
  components: {
    Header,
  },
  setup() {
    return {}
  },
})
